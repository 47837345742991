.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Tracking_div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.Tracking_span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tracking_url {
  color: #7b7b7b !important;
  background-color: white !important;
  padding: 0% !important;
}

.pac-container {
  z-index: 9999 !important;
}

.balance-box {
  background-color: #fff;
  box-shadow: 0px 2px 7px 0px #66666650;
  padding: 20px;
  border-radius: 5px;
}

.balance-box .balance-title {
  color: #7b7b7b;
}

.balance-box .balance-amt {
  color: var(--clr-primary);
  font-weight: 700;
}

.active_btn {
  cursor: pointer;
  background-color: #5d725f;
  transition: all ease-in 0.3s;
  color: #fff;
}
.active_btn:hover {
  background-color: #282e38;
}

.signup_form .form-group input[type="text"].disabled_input_field,
.signup_form .form-group input[type="email"].disabled_input_field {
  cursor: not-allowed !important;
  background-color: #f6f6f6 !important;
}

.active_btn {
  cursor: pointer;
  background-color: var(--clr-primary);
  transition: all ease-in 0.3s;
  color: #fff;
}

.active_btn:hover {
  background-color: #282e38;
}

@media (min-width: 1200px) and (max-width: 1230px) {
  .ipad-box-size {
    margin-top: 100px !important;
    max-width: unset;
  }
}
@media (min-width: 600px) and (max-width: 693px) {
  .ipad-box-size {
    margin-top: 100px !important;
    max-width: unset;
  }
}
