.dropdown{
    width:400px;
    user-select: none;
    position: relative;
}
.dropdown .dropdown-btn {
    padding: 10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0,0.06);
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 7px;
}
.dropdown .dropdown-content {
    position: absolute;
    bottom: 110%;
    left: 0;
    padding: 15px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0,0.06);
    font-weight: 500;
    color: #333;
    width: 100%;
}
.dropdown .dropdown-content .dropdown-item {
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
}
.dropdown .dropdown-content .dropdown-item .unselected-option:hover {
    background: #f4f4f4;
}
.selected-option{
    background: #eef4ea;
}
.selected-option:hover{
    background: #eef4ea;
}